<script>
  import { _ } from "svelte-i18n";
  import { router } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import {
    currentPage,
    exerciseId,
    userId,
    userName,
    assessmentType,
    assessmentId,
    assessmentState,
    assessmentDefinitionId,
    finalScoreDetail,
  } from "../utilities/DataStore";
  import {
    submitFinalAssessmentResults,
    callEvaluationService,
  } from "./components/UploadResults.svelte";

  onMount(async () => {
    initialize();
  });

  export let instanceId;
  let exerciseListStatus = "NEW";
  let exerciseListData;
  let progressListData;
  let instanceData;

  let combinedData = undefined;
  let errorMsg;

  let progressDoneCount = 0;
  let progressFirstActive = undefined;

  let progressTime = 0;
  let progressRemaining;

  let submitFinalAssessmentResultsStatus;

  let intervalId;

  function initialize() {
    Promise.all([loadData(), loadDataProgress(), loadInstanceData()]).then(
      () => {
        console.log("allLoaded");

        combinedData = exerciseListData.map((i) => {
          let p = progressListData.find((x) => x?.id === i?.id);

          if (p) {
            i["status"] = "DONE";
            i["overallScore"] = p.overallScore;
            progressDoneCount++;
          } else {
            if (!progressFirstActive) {
              progressFirstActive = i.id;
            }
          }

          return i;
        });
        console.log("combinedData", combinedData);

        if (progressDoneCount < combinedData.length){
          openExercise(progressFirstActive);
        }

        intervalId = window.setInterval(() => {
          updateProgress();
        }, 1000);
      }
    );
  }

  async function loadData() {
    exerciseListStatus = "LOADING";
    return fetch(
      BASE_URL +
        `/public/assessment/definition/` +
        $assessmentDefinitionId +
        `/exercise`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        exerciseListData = data;
        console.log("exerciseListData", exerciseListData);
        exerciseListStatus = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        exerciseListData = undefined;
        exerciseListStatus = "ERROR";
      });
  }

  async function loadInstanceData() {
    exerciseListStatus = "LOADING";
    return fetch(BASE_URL + `/public/assessment/instance/` + instanceId)
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        instanceData = data;
        console.log(
          Date.parse(instanceData?.createdAt) /
            Date.parse(instanceData?.deadlineAt)
        );
        console.log("instanceData", instanceData);
        exerciseListStatus = "OK";
        updateProgress();
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASESSMENT_INSTANCE";
        instanceData = undefined;
        exerciseListStatus = "ERROR";
      });
  }

  async function loadDataProgress() {
    exerciseListStatus = "LOADING";
    return fetch(
      BASE_URL + `/public/assessment/instance/` + instanceId + `/progress`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        progressListData = data;
        console.log("progressListData", progressListData);
        exerciseListStatus = "OK";
      })
      .catch((error) => {
        console.log("catch error", error);
        errorMsg = "ERROR_LOADING_ASSESSMENT";
        progressListData = undefined;
        exerciseListStatus = "ERROR";
      });
  }

  function openExercise(exercise) {
    console.log("openExercise", exercise);
    $currentPage = "AssessmentExercise";
    $exerciseId = exercise;
    //router.goto("/assessment/" + instanceId + "/exercise/" + exerciseId);
  }

  function submitFinalResult() {
    console.log("submitFinalResults");
    submitFinalAssessmentResultsStatus = "LOADING";
    submitFinalAssessmentResults(instanceId).then(async () => {
      //router.goto("/assessment/" + instanceId + "/results");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      callEvaluationService($assessmentDefinitionId, instanceId).then(
        (data) => {
          intervalId = undefined;
          $assessmentState = "DONE";
          $currentPage = "AssessmentResults";
          $finalScoreDetail = data;
          router.goto('/certificate/'+instanceId);
        }
      );
    });
  }

  function updateProgress() {
    //   console.log(Date.parse(instanceData?.createdAt + "Z"));
    //   console.log(Date.parse(instanceData?.deadlineAt + "Z"));

    //   console.log( Date.now() -
    //     Date.parse(instanceData?.createdAt + "Z"));

    //   console.log((new Date(
    //     Date.now() + new Date().getTimezoneOffset() * 60000
    //   ).getTime()));

    //   console.log((Date.parse(instanceData?.deadlineAt + "Z") -
    //       Date.parse(instanceData?.createdAt + "Z")));

    //   console.log(Date.parse(instanceData?.createdAt + "Z"));
    progressTime = (
      ((Date.now() - Date.parse(instanceData?.createdAt + "Z")) /
        (Date.parse(instanceData?.deadlineAt + "Z") -
          Date.parse(instanceData?.createdAt + "Z"))) *
      100
    ).toFixed(0);

    let reaminingTime = new Date(
      Date.parse(instanceData?.deadlineAt + "Z") - Date.now()
    );
    progressRemaining =
      reaminingTime.getMinutes() +
      ":" +
      (reaminingTime.getSeconds() + "").padStart(2, "0");
    if (reaminingTime < 0) {
      $assessmentState = "DEADLINE";
    }

    if (
      progressDoneCount === combinedData?.length &&
      submitFinalAssessmentResultsStatus !== "LOADING"
    ) {
      submitFinalResult();
    }
  }
</script>

<div class="container">
  <div class="row align-items-center">
    <div class="col">
      <div class="lesson-title">
        {$assessmentType || ''}
      </div>
    </div>
    <div class="col-auto lesson-detail-box">
      <div class="lesson-detail float-right">
        <div>Assessment: {$assessmentId}</div>
        <div style="display: none;">Instance: {instanceId}</div>
        <div>UserName: {$userName}</div>
        <div style="display: none;">UserId: {$userId}</div>
        <div style="display: none;">State: {$assessmentState}</div>
      </div>
    </div>
  </div>

  <div class="card-group">
    {#if exerciseListStatus === "NEW" || exerciseListStatus === "LOADING" || !combinedData}
      <div class="spinner-border align-center" role="status">
        <span class="sr-only">{$_("LOADING")}</span>
      </div>
    {:else if exerciseListStatus === "ERROR"}
      Error loading lesson
    {:else}
      <div class="w-100 mb-1">
        <div style="min-width: 10em;">{$_("PROGRESS")}</div>
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            style="width: {(progressDoneCount / combinedData?.length) * 100}%;"
            aria-valuenow={progressDoneCount}
            aria-valuemin="0"
            aria-valuemax={combinedData?.length}
          />
        </div>
        <div class="d-flex justify-content-center">
          <div>{progressDoneCount}/{combinedData?.length}</div>
        </div>
      </div>
      <div class="w-100 mb-1">
        <div style="min-width: 10em;">{$_("TIME")}</div>
        <div class="progress w-100 mb-1">
          <div
            class="progress-bar"
            class:bg-danger={progressTime >= 100}
            role="progressbar"
            style="width: {progressTime}%;"
            aria-valuenow={progressTime}
            aria-valuemin="0"
            aria-valuemax={100}
          />
        </div>
        <div class="d-flex justify-content-center">
          <div>
            {#if progressTime >= 100}
              100 % ({$_("REMAINING")}: --:--)
            {:else}
              {Math.min(100, progressTime)} % ({$_("REMAINING")}: {progressRemaining})
            {/if}
          </div>
        </div>
      </div>

      {#if progressDoneCount === combinedData?.length || $assessmentState === "DEADLINE"}
        {#if submitFinalAssessmentResultsStatus === "LOADING"}
          <span>{$_("PROCESSING_WAIT")}</span>
          <div class="spinner-border align-center" role="status">
            <span class="sr-only">{$_("LOADING")}</span>
          </div>
        {:else}
          <div>
            <button class="btn btn-success" on:click={submitFinalResult}
              >{$_("SUBMIT_RESULT")}</button
            >
          </div>
        {/if}
      {/if}

      <ul class="list-group w-100">
        {#each combinedData as l, i}
          <li
            class="list-group-item"
            class:active={l.id === progressFirstActive &&
              $assessmentState !== "DEADLINE"}
            class:disabled={l.status === "DONE" ||
              $assessmentState === "DEADLINE"}
            on:click={openExercise(l.id)}
          >
            <div>#{i + 1} - {l.name} - ({l.exerciseType})</div>
            {#if l.status}
              <div style="display:none;">
                {l.status} - {l.overallScore}
              </div>{/if}
          </li>
        {/each}
      </ul>
    {/if}
  </div>
</div>

<style>
  @media (max-width: 768px) {
    .lesson-detail-box {
      display: none;
    }
  }

  .card-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .lesson-title {
    font-weight: 600;
    font-size: large;
  }

  .lesson-detail {
    border: 1px;
    border-color: #9d9fa2;
    border-style: solid;
    border-radius: 5px;
    margin: 0.5em;
    padding: 0.5em;
  }
  
</style>
