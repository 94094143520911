<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount } from "svelte";

  export let time = 90;
  let remainingTime = time;
  let countDownTimerObject;
  let countDownTimerText = formatTime(time);

  let recordingButtonState = "starting";

  const dispatch = createEventDispatcher();

  function formatTime(time) {
    return Math.floor(time / 60) + ":" + ((time % 60) + "").padStart(2, "0");
  }

  onMount(async () => {
    countDownTimerText = formatTime(time);

    startRecording()
  });

  function clickRecordAudio() {
    console.log("clickRecordAudio", recordAudio);
    if (recordAudio) {
      recordCount++;
      recordings = [
        ...recordings,
        { id: recordCount, file: "ABC", duration: 90 },
      ];
      console.log(recordings);
    }

    recordAudio = !recordAudio;
  }

  export function startRecording() {
    remainingTime = time;

    countDownTimerObject = setInterval(function () {
      var c = remainingTime--;

      if (remainingTime < 0) {
        stopRecording();
        countDownTimerText = "Finished";
      } else {
        countDownTimerText = formatTime(c);
      }
    }, 1000);
  }

  export function stopRecording() {
    clearInterval(countDownTimerObject);
    console.log("stopButton clicked");

    //disable the stop button, enable the record too allow for new recordings
    recordingButtonState = "stop";

    dispatch("counterStop");
  }

  
</script>

<div class="row">
  <div class="col">
    {$_("REMAINING_TIME")}: <span class="ml-1" style="font-size: xx-large;"
      >{countDownTimerText}</span
    >
  </div>
</div>
