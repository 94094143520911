<script context="module">
  import { postFile, postJsonFile, getDataOutside } from "../../utilities/Utils.svelte";
  import { BASE_URL } from "../../utilities/config";

  export function uploadMediaFileName(instanceId, exerciseId) {
    return "AS_" + instanceId + "_" + exerciseId + ".ogg";
  }

  export async function uploadMedia(instanceId, exerciseId, dataBlob) {
    let fileName = uploadMediaFileName(instanceId, exerciseId);

    let fileParams = {
      uploadType: "media",
      name: "recordings/" + fileName,
      key: "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o",
    };

    //Upload Recording
    await postFile(
      "https://storage.googleapis.com/upload/storage/v1/b/sensay-assessment/o",
      fileParams,
      dataBlob
    )
      .then((data) => {
        console.log("postFile", data);
      })
      .catch((error) => {
        console.log("error upload to storage");
      });
  }

  function prepareExerciseResultData(
    instanceId,
    exerciseId,
    aggregateScore,
    inputData
  ) {
    return {
      data: JSON.stringify(aggregateScore),
      rawData: JSON.stringify(inputData),
      recordingFileName: uploadMediaFileName(instanceId, exerciseId),
      overallScore: Number.parseFloat(aggregateScore.pronScore),
    };
  }

  export async function uploadExerciseResults(
    instanceId,
    exerciseId,
    aggregateScore,
    exerciseResultData
  ) {
    const exerciseResultDataJson = prepareExerciseResultData(
      instanceId,
      exerciseId,
      aggregateScore,
      exerciseResultData
    );

    await postJsonFile(
      BASE_URL +
        "/public/assessment/instance/" +
        instanceId +
        "/result/" +
        exerciseId,
      { "Content-Type": "application/json" },
      {},
      exerciseResultDataJson
    )
      .then((data) => {
        console.log("send data to sensay", data);
        mixpanel.track("ExerciseResult", {          
          instanceId: instanceId,
          exerciseId: exerciseId,
          aggregateScore: aggregateScore
        });
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }

  export async function submitFinalAssessmentResults(instanceId) {
    await postJsonFile(
      BASE_URL + "/public/assessment/instance/" + instanceId + "/end",
      { "Content-Type": "application/json" },
      {},
      {}
    )
      .then((data) => {
        console.log("send data to sensay", data);        
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }

  export async function callEvaluationService(definitionId, instanceId) {
    await getDataOutside(
      BASE_URL + "/evaluate/evaluate/"+definitionId+"/"+instanceId
    )
      .then((data) => {
        console.log("send data to sensay", data);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }
</script>
