import { writable } from 'svelte/store';

export const compatibility = writable(true);

export const termsAndConditionOpen = writable(false);
export const privacyPolicyOpen = writable(false);

export const feedbackModalOpen = writable(false);


export const userToken = writable(undefined);
export const userLogin = writable(undefined);

export const pin = writable(undefined);
export const pinFromUrl = writable(false);

export const lastRecording = writable(false);

export const recordingState = writable("new");

export const currentPage = writable("init");

export const activationCode = writable(undefined);



export const skillId = writable(undefined);
export const cid = writable(undefined);
export const userId = writable(undefined);
export const userName = writable(undefined);
export const assessmentType = writable(undefined);
export const assessmentId = writable(undefined);
export const assessmentDefinitionId = writable(undefined);
export const instanceId = writable(undefined);
export const assessmentState = writable(undefined);
export const exerciseId = writable(undefined);

export const finalScoreDetail = writable(undefined);