<script>
  import { _ } from "svelte-i18n";
  import Modal from "sv-bootstrap-modal";
  import RangeSlider from "svelte-range-slider-pips";
  import { postJsonFileNoResponse } from "../../utilities/Utils.svelte";
  import { BASE_URL } from "../../utilities/config.js";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    activationCode,
    feedbackModalOpen,
  } from "../../utilities/DataStore";
  import { onMount } from "svelte";

  onMount(async () => {
    if ($activationCode) {
      setTimeout(function () {
        $feedbackModalOpen = true;
      }, 2500);
    }
  });

  let recommendToFriends = [0];
  let commentTextValue = "";

  function closeModal() {
    uploadUserInit();
    feedbackModalOpen.update((x) => false);
  }

  async function uploadUserInit() {
    const recommendToFriendsValue = recommendToFriends[0];
    const uploadUserInit = {
      name: $userName,
      email: $userId,
      assessmentId: $assessmentId,
      assessmentType: $assessmentType,
      recommendToFriends: recommendToFriendsValue,
      commentText: commentTextValue,
      activationCode: $activationCode,
    };

    await postJsonFileNoResponse(
      BASE_URL + "/public/assessment/meetTaipei",
      { "Content-Type": "application/json" },
      {},
      uploadUserInit
    )
      .then((data) => {
        console.log("send data to sensay", data);
        mixpanel.track("MeetTaipeiTracking", uploadUserInit);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }
</script>

<Modal bind:open={$feedbackModalOpen} dialogClasses="modal-xl">
  <div class="modal-header">
    <h5 class="modal-title">{$_("USER_FEEDBACK")}</h5>
    <button
      type="button"
      class="close"
      on:click={() => feedbackModalOpen.update((x) => false)}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h2>{$_("USER_FEEDBACK_TITLE")}</h2>

    <div class="language-level form-floating">
      <label for="customRange3" class="form-label"
        >{$_("RECOMMEND_TO_FRIENDS")}</label
      >
      <RangeSlider
        bind:values={recommendToFriends}
        pips
        float
        all="label"
        min={0}
        max={10}
        step={1}
        pipstep={1}
      />
    </div>

    <div class="form-floating mb-4">
      <label for="codeInput">{$_("FEEDBACK_PROMPT")}</label>
      <textarea style="width:100%" bind:value={commentTextValue} />
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" on:click={closeModal}
        >Send</button
      >
    </div>
  </div>
</Modal>
