<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher, onMount } from "svelte";
  import { sharedTimer, sharedTimerMode } from "../../utilities/DataStore";

  function formatTime(time) {
    if (time <= 0){
      time = 0;
    }
    return Math.floor(time / 60) + ":" + ((time % 60) + "").padStart(2, "0");
  }

  let countDownTimerText;
  onMount(async () => {
    sharedTimer.subscribe((v) => {
      countDownTimerText = formatTime($sharedTimer);
    });
  });
</script>

<div class="row">
  <div class="col">
    {#if $sharedTimerMode === "PREPARE"}{$_("GET_READY_TIME")}{:else}{$_("REMAINING_TIME")}{/if}
    : <span class="ml-1" style="font-size: xx-large;"
      >{countDownTimerText}</span
    >
  </div>
</div>
