<script>
  import { _ } from "svelte-i18n";
  import { init, getLocaleFromNavigator } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFileNoResponse } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    termsAndConditionOpen,
    privacyPolicyOpen,
    activationCode,
    invitationNumber,
  } from "../utilities/DataStore.js";
  import TermsAndConditions from "./components/TermsAndConditions.svelte";
  import PrivacyPolicy from "./components/PrivacyPolicy.svelte";

  export let activationCodeDefault;

  let errorMsg = undefined;
  let loadingState = "NEW";

  let radioAssessmentType = "ENGDEMO";
  let email;
  let name;
  let activationCodeValue = "LITEON";
  let invitationNumberValue;
  let locationProgram;
  let jobFunction;
  let idNumber;

  let isValidEmail;
  let isValidName;
  let isValidActivationCode;
  let isValidLocationProgram;
  let isValidIdNumber;
  let isValidJobFunction;
  let submitTry = false;

  let language;

  onMount(async () => {
    language = getLocaleFromNavigator();
    if (!["en-US", "zh-TW"].includes(language)) {
      language = "en-US";
    }

    const url_string = window.location.href;
    const url = new URL(url_string);
    activationCodeValue = url.searchParams.get("activationCode");
    url.searchParams.delete("activationCode");
    console.log("activationCode", activationCodeValue);

    invitationNumberValue = url.searchParams.get("invitationNumber");
    let iValue = url.searchParams.get("i");
    if (!invitationNumberValue && iValue){
      invitationNumberValue= iValue
    }
    console.log("invitationNumber", invitationNumberValue);

    if (invitationNumberValue) {
      loadInvitationData();
    }

    console.log(222, activationCodeValue, activationCodeDefault);
  });

  async function loadInvitationData() {
    return fetch(
      BASE_URL + `/public/assessment/invitation/${invitationNumberValue}`,
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        let invitationData = data;
      })
      .catch((error) => {
        console.log("catch error", error);
      });
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function validateEmail(value) {
    var input = document.createElement("input");

    input.type = "email";
    input.required = true;
    input.value = value;

    return input.checkValidity();
  }

  function startAssessment() {
    submitTry = true;

    if (email) {
      isValidEmail = validateEmail(email);
    } else {
      isValidEmail = false;
    }

    if (name) {
      isValidName = true;
    } else {
      isValidName = false;
    }

    if (locationProgram) {
      isValidLocationProgram = true;
    } else {
      isValidLocationProgram = false;
    }

    if (jobFunction) {
      isValidJobFunction = true;
    } else {
      isValidJobFunction = false;
    }

    if (idNumber && idNumber.length >= 8 && idNumber.length <= 10) {
      isValidIdNumber = true;
    } else {
      isValidIdNumber = false;
    }

    activationCodeValue = "LITEON";

    if (
      activationCodeValue === "104" ||
      activationCodeValue === "PARO" ||
      activationCodeValue === "STUDY2021" ||
      activationCodeValue === "CLN2021" ||
      activationCodeValue === "TRIAL2021" ||
      activationCodeValue === "NTNU" ||
      activationCodeValue === "DEMO" ||
      activationCodeValue === "LITEON" ||
      activationCodeValue === "ELPAC"
    ) {
      isValidActivationCode = true;
    } else {
      isValidActivationCode = false;
    }

    // isValidActivationCode = true;

    if (
      isValidEmail &&
      isValidName &&
      isValidActivationCode &&
      isValidLocationProgram &&
      isValidJobFunction &&
      isValidIdNumber
    ) {
      $assessmentId = makeid(7);
      // $assessmentType = radioAssessmentType;
      $userId = email;
      $userName = name;
      $activationCode = activationCodeValue;

      loadingState = "LOADING";

      checkOrCreateInvitation().then(() => {
        $assessmentState = "START";
        $currentPage = "AssessmentSelection";
      });

      // uploadUserInit().then(() => {
      //   $assessmentState = "START";
      //   $currentPage = "AssessmentSelection";
      // });
    } else {
      console.log("Validation failed");
    }
  }

  function onChangeType(event) {
    radioAssessmentType = event.currentTarget.value;
  }

  async function uploadUserInit() {
    const uploadUserInit = {
      name: name,
      email: email,
      assessmentId: $assessmentId,
      assessmentType: "INIT",
      activationCode: $activationCode,
      uiLanguage: language,
      locationProgram: locationProgram,
      jobFunction: jobFunction,
      idNumber: idNumber,
    };

    await postJsonFileNoResponse(
      BASE_URL + "/public/assessment/meetTaipei",
      { "Content-Type": "application/json" },
      {},
      uploadUserInit,
    )
      .then((data) => {
        console.log("send data to sensay", data);
        mixpanel.track("MeetTaipeiTracking", uploadUserInit);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }

  let invitationData;
  async function checkOrCreateInvitation() {
    if (invitationNumberValue) {
      await fetch(
        BASE_URL +
          `/public/assessment/invitation/${invitationNumberValue}?clientId=${email}`,
      )
        .then((response) => {
          if (!response.ok) {
            console.log("response error");

            throw new Error("Network response was not ok");
          }
          console.log("response ok", response);
          return response.json();
        })
        .then((data) => {
          invitationData = data;

          $invitationNumber = data?.invitationNumber;

          // const url = new URL(window.location.href);
          // url.searchParams.set("invitationNumber", $invitationNumber);
          router.goto("/invitation/" + $invitationNumber);
          // window.history.replaceState({}, "", url);
        })
        .catch(async (error) => {
          console.log("catch error", error);
        });
    } else {
      await fetch(BASE_URL + `/public/assessment/invitation?clientId=${email}`)
        .then((response) => {
          if (!response.ok) {
            console.log("response error");

            throw new Error("Network response was not ok");
          }
          console.log("response ok", response);
          return response.json();
        })
        .then((data) => {
          invitationData = data;

          $invitationNumber = data?.invitationNumber;

          router.goto("/invitation/" + $invitationNumber);
          // const url = new URL(window.location.href);
          // url.searchParams.set("invitationNumber", $invitationNumber);
          // window.history.replaceState({}, "", url);
        })
        .catch(async (error) => {
          console.log("catch error", error);
        });

      //Create New One if didn't find previous one
      if (!$invitationNumber) {
        const payload = {
          clientName: name,
          clientId: email,
          assessmentType: "TOEIC",
          invitationNumber: makeid(8),
          activationCode: $activationCode,
          status: "NEW",
          data: JSON.stringify({
            locationProgram: locationProgram,
            jobFunction: jobFunction,
            idNumber: idNumber,
          }),
        };

        await fetch(
          BASE_URL + `/public/assessment/invitation?activationCode=LITEON`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          },
        )
          .then((response) => {
            if (!response.ok) {
              console.log("response error");

              throw new Error("Network response was not ok");
            }
            console.log("response ok", response);
            return response.json();
          })
          .then((data) => {
            invitationData = data;

            $invitationNumber = invitationData?.invitationNumber;
            router.goto("/invitation/" + $invitationNumber);
          })
          .catch((error) => {
            console.log("catch error", error);
          });
      }
    }
  }

  function changeLanguage(value) {
    language = value;
    console.log("changeLanguage", language);
    if (language && (language === "en-US" || language === "zh-TW")) {
      init({
        fallbackLocale: "en",
        initialLocale: language,
      });
    } else {
      init({
        fallbackLocale: "en",
        initialLocale: getLocaleFromNavigator(),
      });
    }
  }
</script>

<TermsAndConditions />
<PrivacyPolicy />
<div class="container">
  <div class="px-4 py-1 my-5 text-center">
    <div class="container col-xl-12 col-xxl-10 px-4 py-1">
      <div class="row align-items-start g-lg-5 py-1">
        <div
          class="col-lg-7 text-left align-items-start text-lg-start mb-3 mt-4"
        >
          <!-- <img
            class="d-block mx-auto mb-4"
            src="assets/img/logo_2_report.png"
            alt=""
            width="200px"
          /> -->

          <div class="mt-4 mb-2">
            <span class="yellow-banner">Sensay x LiteOn</span>
          </div>

          <h3 class="display-6 fw-bold lh-1 mb-3">
            {$_("ENGLISH_ASSESSMENT")}
          </h3>
          <!-- <h3 class="display-6 fw-bold lh-1 mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT_EXPLANATION")}
          </h3> -->
          <!-- <h5 class="display-8 fw-bold lh-1 mb-3">{$_("WE_OFFER_TWO_TESTS")}</h5> -->
          <!-- <div class="text-center mb-3">
            {$_("ENG1_AND_ENG2_ARE_FREE_EXPLANATION")}
          </div> -->
          <p class="text-left mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT_EXPLANATION.WHITELABEL")}
          </p>
          <!-- <div class="text-center mb-3">
            {$_("ITS_GREAT_PRACTICE")}
          </div> -->
          <!-- <div class="text-center">
            {$_("FREE_REPORT_WITH_APROXIMATES")}
          </div> -->

          <div class="mb-4" style="margin-top: 10em;">
            <span
              ><a
                href="https://www.oksensay.com/assessment-project-with-ntnu-rcemi/"
                ><img
                  style="margin-right: 1em;"
                  src="assets/img/logo_horizontal_nobg2.png"
                /></a
              ></span
            >
            <span
              ><a href="about:blank" target="_blank" rel="noopener noreferrer">
                <img
                  style="width: 240px; height: auto;"
                  src="assets/img/logo_liteon2.png"
                /></a
              ></span
            >
          </div>
        </div>
        <div class="col-md-10 mx-auto col-lg-5">
          <form class="p-4 p-md-5 border rounded-3 bg-light" on:submit|preventDefault={startAssessment}>
            <div class="form-floating mb-3">
              <label for="emailInput">{$_("EMAIL_ADDRESS")}</label><input
                type="email"
                bind:value={email}
                class="form-control"
                id="emailInput"
                placeholder="name@example.com"
                class:is-valid={submitTry && isValidEmail}
                class:is-invalid={submitTry && !isValidEmail}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">
                {$_("EMAIL_ADDRESS_VALIDATION")}
              </div>
            </div>

            <div class="form-floating mb-3">
              <label for="nameInput">{$_("NAME")}</label><input
                type="text"
                bind:value={name}
                class="form-control"
                id="nameInput"
                placeholder="name"
                class:is-valid={submitTry && isValidName}
                class:is-invalid={submitTry && !isValidName}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">{$_("NAME_VALIDATION")}</div>
            </div>

            <div class="form-floating mb-3">
              <label for="nameInput">{$_("LOCATION_PRORGAM")}</label>

              <select
                class="form-control"
                bind:value={locationProgram}
                class:is-valid={submitTry && isValidLocationProgram}
                class:is-invalid={submitTry && !isValidLocationProgram}
              >
                <option value=""></option>
                <option value="Taiwan">Taiwan</option>
                <option value="Vietnam">Vietnam</option>
                <option value="LIGHT SEEKER">LIGHT SEEKER</option>
                <option value="PRC GZ IVS">PRC GZ IVS</option>
                <option value="PRC GZ IMG">PRC GZ IMG</option>
                <option value="PRC GZ AVS">PRC GZ AVS</option>
                <option value="PRC CA Power">PRC CA Power</option>
                <option value="PRC GZ Enclosure">PRC GZ Enclosure</option>
                <option value="PRC BJ VS SBU">PRC BJ VS SBU</option>
                <option value="PRC QX SS SBU">PRC QX SS SBU</option>
                <option value="PRC CZ HIS SBU">PRC CZ HIS SBU</option>
                <option value="PRC OCS">PRC OCS</option>
                <option value="AEA Guang Zhou">AEA Guang Zhou</option>
              </select>

              <div class="valid-feedback" />
              <div class="invalid-feedback">
                {$_("LOCATION_PRORGAM_VALIDATION")}
              </div>
            </div>

            <div class="form-floating mb-3">
              <label for="nameInput">{$_("JOB_FUNCTION")}</label>
              <select
                class="form-control"
                bind:value={jobFunction}
                class:is-valid={submitTry && isValidJobFunction}
                class:is-invalid={submitTry && !isValidJobFunction}
              >
                <option value=""></option>
                <option value="RD">RD</option>
                <option value="Sale/MKT/CS">Sale/MKT/CS</option>
                <option value="ENG">ENG</option>
                <option value="MFG">MFG</option>
                <option value="LAW">LAW</option>
                <option value="IT">IT</option>
                <option value="GA/OA">GA/OA</option>
                <option value="HR">HR</option>
                <option value="FIN">FIN</option>
                <option value="ADM">ADM</option>
                <option value="QM">QM</option>
                <option value="Audit">Audit</option>
                <option value="PMC">PMC</option>
                <option value="SCM">SCM</option>
                <option value="MOE">MOE</option>
              </select>

              <div class="valid-feedback" />
              <div class="invalid-feedback">
                {$_("JOB_FUNCTION_VALIDATION")}
              </div>
            </div>

            <div class="form-floating mb-3">
              <label for="nameInput">{$_("ID_PASSPORT_NUMBER")}</label><input
                type="text"
                bind:value={idNumber}
                class="form-control"
                id="nameInput"
                placeholder="A234567890"
                class:is-valid={submitTry && isValidIdNumber}
                class:is-invalid={submitTry && !isValidIdNumber}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">
                {$_("ID_PASSPORT_NUMBER_VALIDATION")}
              </div>
            </div>

            {#if loadingState === "NEW"}
              <button type="submit"
                class="w-100 btn btn-lg btn-primary mt-4"
                 tabindex="0"
                on:click={startAssessment}>{$_("START_ASSESSMENT")}</button
              >
            {:else}
              <div class="spinner-border align-center" role="status">
                <span class="sr-only">{$_("LOADING")}</span>
              </div>
            {/if}

            <hr class="my-4" />
            {$_("TERMS_PART1")}<button
              class="btn btn-link p-0" 
              on:click|preventDefault={() => ($termsAndConditionOpen = true)}
              >{$_("TERMS_PART2")}</button
            >
            {$_("TERMS_PART3")}
            <button
              class="btn btn-link p-0"
              on:click|preventDefault={() => ($privacyPolicyOpen = true)}
              >{$_("TERMS_PART4")}</button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .yellow-banner {
    color: #1e1e1e;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #ffcc07;
    padding: 0.3em;
    font-family: "Noto Sans", serif;
  }

  h3 {
    font-family: "Noto Sans", serif;
    font-weight: 800;
    font-size: xxx-large;
  }

  h4 {
    text-align: left;
    font-family: "Noto Sans", serif;
    font-weight: 500;
  }

  label {
    text-align: left;
    text-transform: uppercase;
  }

  p {
    font-family: "Noto Sans", serif;
  }
</style>
