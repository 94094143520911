<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { currentPage } from "../../utilities/DataStore";

  export let instanceId;
  export let instanceData;

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  let progressBarOverall;
  let progressBarSpeaking;
  let progressBarReading;

  let scoreOverall = 75;
  let scoreSpeaking = 80;
  let scoreReading = 45;

  function initialize() {
    console.log(instanceData);
    if (instanceData) {
      let result = JSON.parse(instanceData?.reviewComment);
      scoreOverall = (160 * result?.sumScoreTotal) / result?.sumScoreTotalMax;
      scoreSpeaking =
        160 *
        (result?.sumScoreSpeakingTotal / result?.sumScoreSpeakingTotalMax);
      scoreReading =
        160 * (result?.sumScoreReadingTotal / result?.sumScoreReadingTotalMax);

      createBar(progressBarOverall, scoreOverall? scoreOverall: 0);
      createBar(progressBarSpeaking, scoreSpeaking? scoreSpeaking:0);
      createBar(progressBarReading, scoreReading? scoreReading: 0);
    }
    console.log("score", scoreOverall, scoreSpeaking, scoreReading);
  }

  function formatScore(score) {
    if (score < 10) {
      return "0-15";
    } else if (score < 145) {
      let x =
        score % 10 < 5
          ? (score / 10).toFixed(0) + "0"
          : (score / 10 - 1).toFixed(0) + "5";
      return x + "-" + (+x + 15).toFixed(0);
    } else {
      return "145-160";
    }
  }

  onMount(() => {
    initialize();
  });

  function createBar(item, score) {
    let value = score / 160;
    let colorEnd = "#d12000";
    if (value < 0.2) {
      colorEnd = "#ed8d00";
    } else if (value < 0.4) {
      colorEnd = "#ed8d00";
    } else if (value < 0.55) {
      colorEnd = "#f1bc00";
    } else if (value < 0.7) {
      colorEnd = "#84c42b";
    } else if (value < 0.85) {
      colorEnd = "#53b83a";
    } else {
      colorEnd = "#3da940";
    }
    let bar = new ProgressBar.Line(item, {
      strokeWidth: 3,
      easing: "easeInOut",
      duration: 1000,
      color: "#FFEA82",
      trailColor: "#eee",
      trailWidth: 1,
      svgStyle: { width: "100%", height: "100%" },
      from: { color: "#d12000" },
      to: { color: colorEnd },
      step: (state, bar) => {
        bar.path.setAttribute("stroke", state.color);
      },
    });

    bar.animate(value);
  }

  function shareButton() {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Sensay Assessment Report",
          url: `https://assessment.oksensay.com/#/certificate/${instanceId}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      copyLink();
    }
  }

  let linkCopied = false;

  let timer;

  const debounce = (v) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      linkCopied = false;
    }, 5000);
  };

  function copyLink() {
    let url = `https://assessment.oksensay.com/#/certificate/${instanceId}`;
    copyTextToClipboard(url);
  }

  function copyTextToClipboard(text) {
    linkCopied = true;
    debounce();
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
</script>

<div class="container paper-shadow">
  <div class="row justify-content-center">
    <div class="col sensayLogoSmall" style="">
      <img src="assets/img/logo_2_report.png" width="200px" alt="logo" />
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <h2>{$_("ASSESSMENT")}: {instanceData?.assessmentDefinitionCode}</h2>
      <h2>{instanceData?.clientName}</h2>
      <h6>
        {new Date(instanceData?.finishAt).toLocaleDateString(
          "en-US",
          dateOptions
        )}
      </h6>
      <div>
        <i class="fas fa-lock ml-3"
          ><a
            class="ml-3 sensayLink"
            style="font-family: 'Roboto Slab';"
            href="https://assessment.oksensay.com/#/certificate/{instanceId}"
            >assessment.oksensay.com/#/certificate/{instanceId}</a
          ></i
        >
      </div>
      <div>
        {#if linkCopied}
          <div class="badge bg-info">{$_("LINK_COPIED_TO_CLIPBOARD")}</div>
        {:else}
          <button class="btn" on:click={shareButton}
            ><i class="fas fa-share mr-3" />{$_("SHARE")}</button
          >
        {/if}
      </div>
    </div>
    <div class="col-4 sensayLogo" style="">
      <img src="assets/img/logo_2_report.png" width="200px" alt="logo" />
    </div>
  </div>

  <div class="row">
    <div class="col-2 ">
      <div class="score text-right">{formatScore(scoreOverall)}</div>
    </div>
    <div class="col-10">
      <h4>{$_("OVERALL")}</h4>
      <div>
        {$_("OVERALL_DESCRIPTION")}
      </div>
      <div class="mt-3 mb-3 row">
        <div class="col-auto">10</div>
        <div class="progressBar col" bind:this={progressBarOverall} />
        <div class="col-auto">160</div>
      </div>
      <ul class="sensayScoreDetail">
        {#if scoreOverall < 15}
          <li>
            {$_("OVERALL_LEVEL1")}
          </li>
        {:else if scoreOverall < 30}
          <li>
            {$_("OVERALL_LEVEL2_PART1")}
          </li>
          <li>
            {$_("OVERALL_LEVEL2_PART2")}
          </li>
        {:else if scoreOverall < 60}
          <li>
            {$_("OVERALL_LEVEL3_PART1")}
          </li>
          <li>
            {$_("OVERALL_LEVEL3_PART2")}
          </li>
        {:else if scoreOverall < 90}
          <li>
            {$_("OVERALL_LEVEL4_PART1")}
          </li>
          <li>{$_("OVERALL_LEVEL4_PART2")}</li>
          <li>
            {$_("OVERALL_LEVEL4_PART3")}
          </li>
        {:else if scoreOverall < 115}
          <li>
            {$_("OVERALL_LEVEL5_PART1")}
          </li>
          <li>
            {$_("OVERALL_LEVEL5_PART2")}
          </li>
        {:else if scoreOverall < 135}
          <li>
            {$_("OVERALL_LEVEL6_PART1")}
          </li>
          <li>
            {$_("OVERALL_LEVEL6_PART2")}
          </li>
          <li>{$_("OVERALL_LEVEL6_PART3")}</li>
        {:else}
          <li>
            {$_("OVERALL_LEVEL7_PART1")}
          </li>
          <li>
            {$_("OVERALL_LEVEL7_PART2")}
          </li>
        {/if}
      </ul>
    </div>
  </div>

  <div class="mb-4">
    <hr data-content={$_("DETAILS")} class="hr-text" />
  </div>

  <div class="row">
    <div class="col-2 ">
      <div class="score text-right">{formatScore(scoreReading)}</div>
    </div>
    <div class="col-10">
      <h4>{$_("READING")}</h4>
      <div>
        {$_("READING_DESCRIPTION")}
      </div>
      <div class="mt-3 mb-3 row">
        <div class="col-auto">10</div>
        <div class="progressBar col" bind:this={progressBarReading} />
        <div class="col-auto">160</div>
      </div>
      <ul class="sensayScoreDetail">
        {#if scoreReading < 30}
          <li>
            {$_("READING_LEVEL1_PART1")}
          </li>
        {:else if scoreReading < 60}
          <li>
            {$_("READING_LEVEL2_PART1")}
          </li>
          <li>
            {$_("READING_LEVEL2_PART2")}
          </li>
        {:else if scoreReading < 100}
          <li>
            {$_("READING_LEVEL3_PART1")}
          </li>
          <li>
            {$_("READING_LEVEL3_PART2")}
          </li>
        {:else if scoreReading < 130}
          <li>
            {$_("READING_LEVEL4_PART1")}
          </li>
          <li>
            {$_("READING_LEVEL4_PART2")}
          </li>
        {:else}
          <li>
            {$_("READING_LEVEL5_PART1")}
          </li>
          <li>
            {$_("READING_LEVEL5_PART2")}
          </li>
        {/if}
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="col-2 ">
      <div class="score text-right">{formatScore(scoreSpeaking)}</div>
    </div>
    <div class="col-10">
      <h4>{$_("SPEAKING")}</h4>
      <div>
        {$_("SPEAKING_DESCRIPTION")}
      </div>
      <div class="mt-3 mb-3 row">
        <div class="col-auto">10</div>
        <div class="progressBar col" bind:this={progressBarSpeaking} />
        <div class="col-auto">160</div>
      </div>
      <ul class="sensayScoreDetail">
        {#if scoreSpeaking < 30}
          <li>
            {$_("SPEAKING_LEVEL1_PART1")}
          </li>
        {:else if scoreSpeaking < 60}
          <li>
            {$_("SPEAKING_LEVEL2_PART1")}
          </li>
        {:else if scoreSpeaking < 100}
          <li>
            {$_("SPEAKING_LEVEL3_PART1")}
          </li>
        {:else if scoreSpeaking < 130}
          <li>
            {$_("SPEAKING_LEVEL4_PART1")}
          </li>
        {:else}
          <li>
            {$_("SPEAKING_LEVEL5_PART1")}
          </li>
        {/if}
      </ul>
    </div>
  </div>

  <div class="mb-4">
    <hr data-content={$_("SCORE_CHART")} class="hr-text" />
  </div>

  <div class="row justify-content-center">
    <div class="col-11">
      <img src="assets/img/result_chart.png" width="100%" alt="result charts" />
    </div>
  </div>

  <div class="row justify-content-center">
      <a
        href="/"
        on:click={() => {
          $currentPage = "";
        }}>assessment.oksensay.com</a
      >
  </div>
</div>

<style>
  .sensayLogo {
    text-align: end;
    min-width: 200px;
  }
  .sensayLogoSmall {
    display: none;
  }

  @media (max-width: 768px) {
    .container {
      padding: 0;
    }

    .sensayLogo {
      max-width: 200px;
      display: none;
    }

    .sensayLogoSmall {
      display: block;
      flex-grow: 0;
    }

    .sensayScoreDetail {
      display: none;
    }

    .sensayLink {
      font-size: 2.5vw;
    }

    .score {
      font-size: 3.5vw !important;
    }
  }

  .score {
    color: #ffcc0c;
    font-size: x-large;
    font-weight: 700;
  }

  body {
    background: #dfe6e9;
  }
  .paper-shadow {
    position: relative;
    background: #fff;
    padding: 3rem;
    /* width: 50%; */
    margin: 50px auto;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
    /* height: 400px; */
  }
  .paper-shadow:after,
  .paper-shadow:before {
    content: "";
    position: absolute;
    left: auto;
    background: none;
    z-index: -1;
  }
  .paper-shadow:after {
    width: 90%;
    height: 10px;
    top: 30px;
    right: 8px;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-box-shadow: 0px -20px 36px 5px #295d92;
    -moz-box-shadow: 0px -20px 36px 5px #295d92;
    box-shadow: 0px -25px 35px 0px rgba(0, 0, 0, 0.5);
  }

  .paper-shadow:before {
    width: 10px;
    height: 95%;
    top: 5px;
    right: 18px;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-box-shadow: 20px 0px 25px 5px #295d92;
    -moz-box-shadow: 20px 0px 25px 5px #295d92;
    box-shadow: 22px 0px 35px 0px rgba(0, 0, 0, 0.5);
  }

  hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
  }

  hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
