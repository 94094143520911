<script>
  import { onMount } from "svelte";
  export let textToRead;
  export let language;
  let ttsButtonState = "starting";

  // subscription key and region for speech services.
  let subscriptionKey;
  let serviceRegion = "eastasia";
  let authorizationToken = "";
  let SpeechSDK;
  let synthesizer;
  let player;
  let audioConfig;
  let ttsToken;
  let speechCounter = 0;

  onMount(async () => {
    intialize();
  });

  function intialize() {
    if (!!window.SpeechSDK) {
      SpeechSDK = window.SpeechSDK;
      ttsButtonState = "starting";

      initPlayPage();
    } else {
      ttsButtonState = "error";
      console.log("error with SpeechSDK");
    }
  }

  function initPlayPage() {
    let data = {
      "Ocp-Apim-Subscription-Key": "f5f51df19b84457fa04c08923439e070", //"67dbd5e5569546ffaf530da9b90b80d0",
    };
    postToken(
      "https://eastasia.api.cognitive.microsoft.com/sts/v1.0/issuetoken",
      data
    )
      .then((data) => {
        console.log("token", data);
        ttsToken = data;
        ttsButtonState = "ready";
      })
      .catch((error) => {
        console.log("token", error);
        ttsButtonState = "error";
      });
  }

  async function postToken(url = "", data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        //'Content-Type': 'application/json'
        "Content-Type": "application/x-www-form-urlencoded",
        ...data,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    console.log("postToken - status", response.status);
    //uploadStatus.innerHTML = response.status;
    return response.text(); // parses JSON response into native JavaScript objects
  }

  function playTextToSpeech() {
    if (ttsToken != null) {
      authorizationToken = ttsToken;
    } else {
      console.log("missing token");
      return;
    }
    let voiceName;

    // if we got an authorization token, use the token. Otherwise use the provided subscription key
    let speechConfig;
    if (authorizationToken) {
      speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(
        authorizationToken,
        serviceRegion
      );
    } else {
      if (
        subscriptionKey.value === "" ||
        subscriptionKey.value === "subscription"
      ) {
        alert(
          "Please enter your Microsoft Cognitive Services Speech subscription key!"
        );
        ttsButtonState = "error";
        return;
      }
      speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        subscriptionKey.value,
        serviceRegion
      );
    }
    if (language == "en-us") {
      language = "en-US";
    } else if (language == "cs-cz") {
      language = "cs-CZ";
    } else if (language == "zh-tw") {
      language = "zh-TW";
    } else if (language == "de-de") {
      language = "de-DE";
    }

    speechConfig.speechSynthesisLanguage = language;
    if (language == "en-US") {
      if (speechCounter % 2 == 1) {
        speechConfig.speechSynthesisVoiceName = "en-US-GuyRUS";
        voiceName = "en-US-GuyRUS";
      } else {
        voiceName = "en-US-JennyNeural";
      }
    } else if (language == "cs-CZ") {
      if (speechCounter % 2 == 1) {
        speechConfig.speechSynthesisVoiceName = "cs-CZ-VlastaNeural";
        voiceName = "cs-CZ-VlastaNeural";
      } else {
        voiceName = "cs-CZ-AntoninNeural";
      }
    } else if (language == "zh-TW") {
      if (speechCounter % 2 == 1) {
        speechConfig.speechSynthesisVoiceName = "zh-TW-HsiaoChenNeural";
        voiceName = "zh-TW-HsiaoChenNeural";
      } else {
        voiceName = "zh-TW-YunJheNeural";
      }
    } else if (language === "de-DE") {
      if (speechCounter % 2 == 1) {
        speechConfig.speechSynthesisVoiceName = "de-DE-KatjaNeural";
        voiceName = "de-DE-KatjaNeural";
      } else {
        voiceName = "de-DE-ConradNeural";
      }
    }
    speechCounter++;

    //speechConfig.speechSynthesisOutputFormat =
    //  SpeechSDK.SpeechSynthesisOutputFormat.Ogg16Khz16BitMonoOpus;

    player = new SpeechSDK.SpeakerAudioDestination();
    audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);
    synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);

    ttsButtonState = "playing";

    let ssml =
      `<speak xmlns="http://www.w3.org/2001/10/synthesis"
       xmlns:mstts="http://www.w3.org/2001/mstts"
       xmlns:emo="http://www.w3.org/2009/10/emotionml"
       version="1.0"
       xml:lang="` +
      language +
      `">
	<voice name="` +
      voiceName +
      `">
		<prosody rate="-20%"
		         pitch="0%">` +
      textToRead +
      `</prosody>
	</voice>
</speak>`;

    synthesizer.speakSsmlAsync(
      ssml,
      function (result) {
        console.log(result);
        if (
          result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log("synthesis finished for [" + textToRead + "]");
        } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
          console.log("synthesis failed. Error detail: " + result.errorDetails);
          ttsButtonState = "error";
        }
        synthesizer.close();
        synthesizer = undefined;
      },
      function (err) {
        ttsButtonState = "error";
        console.log("Synthesizer Error", err);

        synthesizer.close();
        synthesizer = undefined;
      }
    );

    player.onAudioEnd = function (s) {
      console.log("onAudioEnd", s);
      ttsButtonState = "ready";
    };
  }

  export function stopTextToSpeech() {
    console.log(player);
    if (player != null) {
      player.pause();
      ttsButtonState = "ready";
    }
  }

  function clickPlayTTS() {
    console.log("clickPlayTTS", ttsButtonState);
    if (ttsButtonState === "ready") {
      playTextToSpeech();
      ttsButtonState = "playing";
    } else if (ttsButtonState === "playing") {
      stopTextToSpeech();
    }
  }
</script>

{#if ttsButtonState === "starting"}
  TTS initializing...
{:else if ttsButtonState === "playing"}
  <button class="btn btn-success" on:click={clickPlayTTS} type="button">
    <i
      class="fas fa-volume-up spin"
      style="font-size:1.5em; padding: 0.4rem 0rem 0.4rem 0rem;"
    />
  </button>
{:else if ttsButtonState === "ready"}
  <button class="btn btn-primary" on:click={clickPlayTTS} type="button"
    ><i
      class="fas fa-volume-up"
      style="font-size:1.5em; padding: 0.4rem 0rem 0.4rem 0rem;"
    /></button
  >
{:else if ttsButtonState === "error"}TTS Error!{/if}

<style>
  .spin {
    animation-name: stretch;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  }

  @keyframes stretch {
    50% {
      color: black;
    }
  }
</style>
