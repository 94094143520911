<svelte:options accessors />

<script>
  import { onDestroy } from "svelte";
  import { postFile } from "../../utilities/Utils.svelte";
  import { createEventDispatcher, onMount } from "svelte";

  export let instanceId;
  export let exerciseId;

  const dispatch = createEventDispatcher();

  let state = "CAPTURE";
  let errorMsg = null;
  let atempt = 0;

  let photo1;
  let video;

  // Variables to store the captured image Blobs
  let firstPhotoBlob = null;
  let stream;

  export let fileNames = [];

  let isPhotoUploaded = false;

  onMount(() => {
    dispatch("initPhotoVerification");
    enableCamera()
  });

  onDestroy(() => {
    console.log("the component is being destroyed");
    stopWebcam();
  });

  /**
   * Starts the webcam by requesting access via getUserMedia.
   * If successful, the live video stream is shown and the capture button is enabled.
   */
  function startWebcam() {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((s) => {
        stream = s;
        video.srcObject = stream;
        video.style.display = "block";
        captureBtn.style.display = "inline-block";
        enableCameraBtn.style.display = "none";
      
        setTimeout(() => {
          try {
            capture();
          } catch (error) {
            console.error("Error capturing photo", error);
          }
        }, 500);

      })
      .catch((err) => {
        console.error("Error accessing the webcam:", err);
        state = "ERROR";
        errorMsg = "Could not access the webcam. Please allow camera access and try again.";
      });
  }

  /**
   * Stops the webcam by stopping all tracks of the media stream.
   */
  function stopWebcam() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      if (video) {
        video.srcObject = null;
      }
      console.log("Webcam stopped.");
    }
  }

  /**
   * When the user clicks the "Enable Camera" button, check for camera permissions
   * (if supported) and then request the webcam stream.
   */
  function enableCamera() {
    if (navigator.permissions) {
      // Query the camera permission state. Note: This API support varies by browser.
      navigator.permissions
        .query({ name: "camera" })
        .then((permissionStatus) => {
          console.log("Camera permission state is", permissionStatus.state);
          if (permissionStatus.state === "granted") {
            // Permission already granted, so just start the webcam.
            startWebcam();
          } else if (permissionStatus.state === "prompt") {
            // Permission not yet granted; calling getUserMedia will trigger the prompt.
            startWebcam();
          } else {
            // If the permission has been denied.
            errorMsg = "Camera access has been denied. Please enable it in your browser settings.";
            state = "ERROR";
          }
        })
        .catch((error) => {
          console.error("Permissions API error:", error);
          // Fallback: Directly call getUserMedia if Permissions API is not fully supported.
          startWebcam();
        });
    } else {
      // If Permissions API is not supported, simply request the webcam stream.
      startWebcam();
    }
  }

  /**
   * Captures an image from the video stream.
   * @param {Function} callback - Called with the Blob and data URL of the captured image.
   */
  function captureImage(callback) {
    // Create an off-screen canvas matching the video dimensions.
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");

    // Draw the current frame from the video onto the canvas.
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a Blob and also get a data URL for display.
    canvas.toBlob((blob) => {
      const dataUrl = canvas.toDataURL("image/png");
      callback(blob, dataUrl);
    }, "image/png");
  }

  function generateRandomString(length) {
    // Define the characters to choose from
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      // Pick a random index from the characters string
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  async function uploadMedia() {
    let hash = generateRandomString(6);

    let fileName = `p_${instanceId}_${exerciseId}_${atempt}_${hash}.png`;

    fileNames.push(fileName);

    let fileParams = {
      uploadType: "media",
      name: "photo-verify/" + fileName,
      key: "AIzaSyBgFT_wbOWOauPZpCWoBiRVGmgFdfHvr6o",
    };

    //Upload Recording
    await postFile(
      "https://storage.googleapis.com/upload/storage/v1/b/sensay-assessment/o",
      fileParams,
      firstPhotoBlob,
    )
      .then((data) => {
        console.log("postFile", data);

        isPhotoUploaded = true;
        dispatch("photoUploaded");
      })
      .catch((error) => {
        console.log("error upload to storage");
      });
  }

  // When the user clicks the "Take Photo" button...
  async function capture() {
    atempt++;

    // Capture the first photo immediately.
    captureImage((blob, dataUrl) => {
      firstPhotoBlob = blob;
      photo1.src = dataUrl;
      console.log("First photo captured.");

      if (!blob || blob.size === 0) {
        console.error("Captured image is empty or invalid.");
        errorMsg = "Failed to capture a valid image. Please try again."
        state = "ERROR";
        return;
      }

      if (blob.type !== "image/png") {
        console.error("Captured image is not of type PNG.");
        errorMsg = "Captured image is not a valid PNG file. Please try again."
        state = "ERROR";
        return;
      }

      uploadMedia();
    });

    state = "VERIFY";
  }

  function tryAgain() {
    stopWebcam()
    state = "CAPTURE";
    startWebcam();
  }
</script>

<div class="row">
  <div class="col">
    <div>
      <h3>Keep Your Camera On to Ensure Exam Integrity</h3>
      <p>
        Keep your webcam on throughout the entire assessment to help maintain
        exam integrity. Please make sure you're in a well-lit, quiet environment
        before starting.
      </p>
    </div>

    {#if state === "CAPTURE"}
      <div class="photo-container">
        <button
          class="btn btn-primary"
          id="enableCameraBtn"
          on:click={enableCamera}>Enable Camera</button
        >
        <br />
        <video
          bind:this={video}
          id="video"
          autoplay
          playsinline
          style="display: none;"
        ></video>
        <br />
        <button
          class="btn btn-primary"
          id="captureBtn"
          style="display: none;"
          on:click={capture}>Take Photo</button
        >
      </div>
    {:else if state === "VERIFY"}
      <div class="photo-container">
        <h2>Captured Photos:</h2>
        <div>
          <img bind:this={photo1} id="photo1" alt="First captured photo" />
          <br />
          <button class="btn btn-primary mt-4" on:click={tryAgain}
            >Try Again</button
          >
        </div>
      </div>
    {:else if state === "ERROR"}
    <div class="photo-container">
      <h2>Error</h2>

      <div>
        <p>{errorMsg}</p>
        <br />
        <button class="btn btn-primary mt-4" on:click={tryAgain}
          >Try Again</button
        >
      </div>
    </div>
    {/if}
  </div>
</div>

<style>
  video,
  img {
    max-width: 100%;
    height: auto;
  }
  .photo-container {
    margin-top: 20px;
  }
</style>
