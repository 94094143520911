<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { getCookie, setCookie } from "../../utilities/Utils.svelte";
  let instructionsOpen = true;

  export let exerciseType;

  onMount(async () => {
    let instructionsOpenCookie = getCookie("instructionsOpen");
    if (instructionsOpenCookie === "N") {
      instructionsOpen = false;
    }
  });

  function toggleInstuction() {
    instructionsOpen = !instructionsOpen;
    if (instructionsOpen) {
      setCookie("instructionsOpen", "Y", 30);
    } else {
      setCookie("instructionsOpen", "N", 30);
    }
  }
</script>

<div class="row justify-content-center">
  <div class="col-md-auto">
    <div class="instructions" on:click={toggleInstuction}>
      {#if instructionsOpen}
        {#if exerciseType === "INTRO"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_INTRO")}
          </div>
        {:else if exerciseType === "READ"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_READ")}
          </div>
        {:else if exerciseType === "QUESTION"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_QUESTION")}
          </div>
        {:else if exerciseType === "VIDEO"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_VIDEO")}
          </div>
        {:else if exerciseType === "IMAGE"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_IMAGE")}
          </div>
        {:else if exerciseType === "LISTEN"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_("INSTRUCTIONS_LISTEN_1")}
            <i class="fas fa-volume-up" style="font-size: 1em" />
            {$_("INSTRUCTIONS_LISTEN_2")}
          </div>
          <div class="mt-4">
            {$_("INSTRUCTIONS_LISTEN_3")}
          </div>
        {:else if exerciseType === "MULTICHOICE"}
          <div>
            <strong>{$_("INSTRUCTIONS")}: </strong>{$_(
              "INSTRUCTIONS_MULTICHOICE",
            )}
          </div>
        {:else}
          <strong>{$_("INSTRUCTIONS")}: </strong> ---
        {/if}
      {:else}
        <div style="text-align: center">{$_("INSTRUCTIONS")}</div>
      {/if}
    </div>
  </div>
</div>

<style>
  .instructions {
    border-color: #ffcc0c;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    padding: 1em;
    font-size: 0.8em;
  }
  @media (min-width: 768px) {
    .instructions {
      padding: 1em;
      font-size: 1em;
    }
  }
</style>
