<script>
  import { Route, router, meta } from "tinro";
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import ExerciseDetail from "./exercise/ExerciseDetail.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    assessmentState,
    instanceId,
    exerciseId,
  } from "./utilities/DataStore";

  import AssessmentOverview from "./assessment/AssessmentOverview.svelte";
  import InitPage from "./assessment/InitPage.svelte";

  import TopHeaderComponent from "./utilities/TopHeaderComponent.svelte";
  import AssessmentExercise from "./assessment/AssessmentExercise.svelte";
  import AssessmentResults from "./assessment/AssessmentResults.svelte";
  import IntroductionPage from "./assessment/IntroductionPage.svelte";
  import LandingPage from "./assessment/LandingPage.svelte";
  import LandingPage104Demo from "./assessment/LandingPage104Demo.svelte";
  import LandingPageFree from "./assessment/LandingPageFree.svelte";
  import { addMessages, init, getLocaleFromNavigator } from "svelte-i18n";

  import en from "./i18n/en.json";
  import zhTW from "./i18n/zh-TW.json";
  import PreviousExamPage from "./assessment/PreviousExamPage.svelte";
  import AssessmentSelection from "./assessment/AssessmentSelection.svelte";
    import LandingPageNtnu from "./assessment/LandingPageNtnu.svelte";

  addMessages("en", en);
  addMessages("zh-TW", zhTW);

  router.mode.hash();

  console.log("getLocaleFromNavigator", getLocaleFromNavigator());
  init({
    fallbackLocale: "en",
    initialLocale: getLocaleFromNavigator(),
  });

  onMount(async () => {
    initParamsFromUrl();
  });

  function initParamsFromUrl() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    if (url.hash.startsWith("#/certificate/")) {
      $instanceId = url.hash?.split("/").slice(-1);
      $currentPage = "AssessmentResults";
      console.log("Open certificate");
      return;
    }

    $userId = url.searchParams.get("userId");
    $userName = url.searchParams.get("userName");
    $assessmentType = url.searchParams.get("assessmentType");
    $assessmentId = url.searchParams.get("assessmentId");
    $skillId = url.searchParams.get("skillId");
    $cid = url.searchParams.get("cid");
    url.searchParams.delete("userId");
    url.searchParams.delete("userName");
    url.searchParams.delete("assessmentType");
    url.searchParams.delete("assessmentId");
    url.searchParams.delete("skillId");
    url.searchParams.delete("cid");

    console.log("userId", $userId);
    console.log("userName", $userName);
    console.log("assessmentType", $assessmentType);
    console.log("assessmentId", $assessmentId);

    mixpanel.track("Init", {
      userId: $userId,
      userName: $userName,
      assessmentType: $assessmentType,
      assessmentId: $assessmentId,
      skillId: $skillId,
      cid: $cid,
    });

    if ($assessmentId && $userId && $assessmentType && $skillId && $cid) {
      //router.goto("/assessment/" + $assessmentId);
      $currentPage = "Landing";
    }

    if (url.hash.startsWith("#/progress/")) {
      $exerciseId = url.hash?.split("/").slice(-1);
      $instanceId = url.hash?.split("/").slice(-2, -1);

      console.log("Open Progress", $exerciseId, $instanceId);
      if ($exerciseId && instanceId) {
        //router.goto("/assessment/" + $assessmentId);
        $currentPage = "AssessmentResults";
      }
    }
  }
</script>

{#key $currentPage || activationCode}
  {#if $currentPage === "Landing"}
    <InitPage />
  {:else if $currentPage === "AssessmentOverview"}
    <AssessmentOverview instanceId={$instanceId} />
  {:else if $currentPage === "IntroductionPage"}
    <IntroductionPage />
  {:else if $currentPage === "AssessmentExercise"}
    <AssessmentExercise instanceId={$instanceId} exerciseId={$exerciseId} />
  {:else if $currentPage === "AssessmentResults"}
    <AssessmentResults
      instanceId={$instanceId}
      on:resumeAssessment={() => {
        $currentPage = "AssessmentExercise";
        console.log(111)
      }}
    />
  {:else if $currentPage === "AssessmentSelection"}
    <AssessmentSelection />
  {:else if $currentPage === "PreviousExam"}
    <PreviousExamPage />
  {:else}
    <!-- <LandingPage104Demo></LandingPage104Demo>   -->
    <!-- <AssessmentSelection /> -->
    <LandingPageNtnu />
  {/if}
{/key}
<!-- <Route>
  <Route path="/" fallback>Fallback</Route>

  <Route path="/" let:meta>
    <InitPage />
  </Route>

  <Route path="/assessment/:instanceId" let:meta>
    <AssessmentOverview instanceId={meta.params.instanceId} />
  </Route>

  <Route path="/assessment/:instanceId/exercise/:exerciseId" let:meta>
    <AssessmentExercise
      instanceId={meta.params.instanceId}
      exerciseId={meta.params.exerciseId}
    />
  </Route>

  <Route path="/assessment/:instanceId/results" let:meta>
    <AssessmentResults instanceId={meta.params.instanceId} />
  </Route>
</Route> -->
