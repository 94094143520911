<script>
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
  } from "../utilities/DataStore";

  let errorMsg = undefined;

  onMount(async () => {
    initialize();
  });

  function initialize() {
    loadAsessmentProgress($cid, $skillId, $userId);
  }

  let instanceData = undefined;
  let instanceStatus = "NEW";

  async function loadAsessmentProgress(cid, skillId, userId) {
    instanceStatus = "LOADING";
    return fetch(
      BASE_URL + `/public/assessment/instance?cid=${cid}&skillId=${skillId}&userId=${userId}`
    )
      .then((response) => {
        if (!response.ok) {
          console.log("response error");

          throw new Error("Network response was not ok");
        }
        console.log("response ok", response);
        return response.json();
      })
      .then((data) => {
        instanceData = data;
        console.log("instanceData", instanceData);
        instanceStatus = "OK";
        $instanceId = data.id;
        $assessmentDefinitionId = data.assessmentDefinitionId;
        $assessmentState = data.status;
        if (data.status === "SUBMITTED" || data.status === "SCORED") {
          $currentPage = "AssessmentResults";
        } else {
          $currentPage = "AssessmentOverview";
        }
      })
      .catch((error) => {
        console.log("catch error", error);
        instanceStatus = "OK";
      });
  }

  function resumeAssessment() {
    $currentPage = "AssessmentOverview";
  }

  function startAssessment() {
    $assessmentState = "START";
    $currentPage = "IntroductionPage";
    
  }

  
</script>

<div class="login-clean">
  <form>
    <h2 class="sr-only">Login Form</h2>
    <div class="illustration">
      <img src="assets/img/logo_2.png" alt="logo" />
    </div>
    {#if !instanceData && instanceStatus != "OK"}
      <div class="row justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    {:else}
      <div class="form-group"><p>AssessmentType: {$assessmentType}</p></div>

      <div class="form-group"><p>Name: {$userName}</p></div>
      <div style="display: none;">
        <div class="form-group"><p>AssessmentId: {$assessmentId}</p></div>
        <div class="form-group"><p>UserId: {$userId}</p></div>
        <div class="form-group"><p>SkillId: {$skillId}</p></div>
        <div class="form-group"><p>cid: {$cid}</p></div>
      </div>

      {#if true}
        {#if $assessmentType && $assessmentId && $userId && $skillId && $cid && !$instanceId}
          <div class="form-group">
            <button
              class="btn btn-primary btn-block"
              type="submit"
              on:click|preventDefault={startAssessment}>Start Assessment</button
            >
          </div>
        {:else if $assessmentType && $assessmentDefinitionId && $assessmentId && $userId && !$instanceId}
          <div class="form-group">
            <button
              class="btn btn-primary btn-block"
              type="submit"
              on:click|preventDefault={resumeAssessment}
              >Resume Assessment</button
            >
          </div>
        {:else}
          <div class="alert alert-danger" role="alert">
            Sorry, you can only initiate the assessment from another platform.
          </div>
        {/if}
      {:else}
        <div class="form-group">
          <button class="btn btn-danger btn-block" type="button" disabled
            >Error</button
          >
          <div class="alert alert-danger" role="alert">
            Due to technical limitations of iOS, Sensay only works directly in
            Safari. If you open as link from Line or FB please explicitly open
            it. <a href="https://www.oksensay.com/337-2/">Need help?</a>
          </div>
        </div>
      {/if}

      {#if errorMsg === "Missing PIN"}
        <div class="alert alert-danger" role="alert">
          This PIN doesn't exist, please check with your teacher.
        </div>
      {:else if errorMsg === "No PIN or Name"}
        <div class="alert alert-danger" role="alert">
          Please fill token and name.
        </div>
      {:else if errorMsg}
        <div class="alert alert-danger" role="alert">
          {errorMsg}
        </div>
      {/if}
    {/if}
  </form>
</div>

<style>
  form {
    max-width: 450px;
  }
  img {
    width: 100%;
  }
</style>
