<script>
  import { _ } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import RangeSlider from "svelte-range-slider-pips";
  import { postJsonFileNoResponse } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    activationCode,
  } from "../utilities/DataStore";

  let errorMsg = undefined;
  let loadingState = "NEW";
  let certificationScore = undefined;
  let certificationType = undefined;

  onMount(async () => {});

  function startAssessment(assessmentTypeValue) {
    console.log(555);
    loadingState = "LOADING";

    if (assessmentTypeValue === "INTRO1") {
      $assessmentType = assessmentTypeValue;
      $currentPage = "IntroductionPage";
    } else if (assessmentTypeValue === "NTNU_POC_1") {
      $assessmentType = assessmentTypeValue;
      $currentPage = "IntroductionPage";
    }else if (assessmentTypeValue === "NTNU_POC_2") {
      $assessmentType = assessmentTypeValue;
      $currentPage = "IntroductionPage";
    } else {
      $assessmentType = assessmentTypeValue;
      $currentPage = "PreviousExam";
    }
  }
</script>

<div class="container">
  <div class="px-4 py-5 my-1 text-center">
    <div class="col-lg-9 mx-auto">
      <h2 class="lead mb-4">{$_("PLEASE_SELECT_ASSESSMENT")}</h2>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
        {#if $activationCode === "NTNU"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">NTNU POC - 1 (Medical)</div>
              <div class="card-body">
                <p class="card-text">{$_("SEE_HOW_IT_WORKS")}</p>
                <p class="card-text">{$_("SCORE_NOT_REPRESENTATIVE")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("NTNU_POC_1")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">15 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">Speaking Proficiency Test Framework</div>
              <div class="card-body">
                <p class="card-text">Integrating Academic Literacy and Academic Reading Skills on EMI</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("NTNU_POC_2")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">20 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if $activationCode === "GJUN"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">GJUN - DEMO - 1</div>
              <div class="card-body">
                <p class="card-text">{$_("SEE_HOW_IT_WORKS")}</p>
                <p class="card-text">{$_("SCORE_NOT_REPRESENTATIVE")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("GJUN_DEMO")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">5 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if $activationCode === "CRANE"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">GEPT - 1</div>
              <div class="card-body">
                <p class="card-text">{$_("SEE_HOW_IT_WORKS")}</p>
                <p class="card-text">{$_("SCORE_NOT_REPRESENTATIVE")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("CRANE_GEPT_1")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">15 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if $activationCode === "DEMO"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">Demo</div>
              <div class="card-body">
                <p class="card-text">{$_("SEE_HOW_IT_WORKS")}</p>
                <p class="card-text">{$_("SCORE_NOT_REPRESENTATIVE")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("ENGDEMO")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">3 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if $activationCode === "104"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">Intro1</div>
              <div class="card-body">
                <p class="card-text">Self Introduction v1</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("INTRO1")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">3 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        

        {#if $activationCode !== "104" && $activationCode !== "NTNU"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">Eng-1</div>
              <div class="card-body">
                <p class="card-text">Advanced Beginner</p>
                <p class="card-text">CEFR A2</p>
                <p class="card-text">16 {$_("QUESTIONS")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("ENG1")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">10 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}

        {#if $activationCode !== "104" && $activationCode !== "NTNU"}
          <div class="col">
            <div class="card shadow-sm mb-4">
              <div class="card-header">Eng-2</div>
              <div class="card-body">
                <p class="card-text">Intermediate</p>
                <p class="card-text">CEFR B2</p>
                <p class="card-text">17 {$_("QUESTIONS")}</p>
              </div>
              <div class="card-footer">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn btn-sm btn-outline-primary"
                      on:click={() => startAssessment("ENG2")}
                      >{$_("START")}</button
                    >
                  </div>
                  <small class="text-muted">15 {$_("MINS")}</small>
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .card {
    min-height: 20em;
  }
</style>
