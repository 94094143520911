<script>
  import { _ } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    activationCode,
  } from "../utilities/DataStore";

  let errorMsg = undefined;
  let loadingState = "NEW";

  onMount(async () => {});

  function confirmStart() {
    loadingState = "LOADING";
    startAssessmentInstance(
      $userId,
      $userName,
      $assessmentId,
      $assessmentType,
      $skillId,
      $cid
    )
      .then((data) => {
        //router.goto("/assessment/" + $instanceId);
        $currentPage = "AssessmentOverview";
      })
      .catch((error) => {
        errorMsg = "Error" + error;
      });
  }

  async function startAssessmentInstance(
    clientId,
    clientName,
    assessmentId,
    assessmentDefinitionCode,
    skillId,
    cid
  ) {
    const assessmentInstance = {
      clientId: clientId,
      clientName: clientName,
      assessmentId: assessmentId,
      status: "NEW",
      assessmentDefinitionCode: assessmentDefinitionCode,
      skillId: skillId,
      cid: cid,
    };

    await postJsonFile(
      BASE_URL + "/public/assessment/instance/",
      { "Content-Type": "application/json" },
      {},
      assessmentInstance
    )
      .then((data) => {
        console.log("send data to sensay", data);
        $assessmentDefinitionId = data.assessmentDefinitionId;
        $assessmentState = data.status;
        $instanceId = data.id;

        mixpanel.track("StartInstance", assessmentInstance);
      })
      .catch((error) => {
        errorMsg = error;
        console.error("error sending data to sensay", error);
        throw new Error(error);
      });
  }
</script>

<div class="container">
  <div class="px-4 py-5 my-1 text-center">
    <img
      class="d-block mx-auto mb-4"
      src="assets/img/logo_2.png"
      alt=""
      width="200px"
    />
    <h1 class="display-5 fw-bold">{$_("SENSAY_ASSESSMENT")}</h1>
    <div class="col-lg-9 mx-auto">
      <h2 class="lead mb-4">{$_("INSTRUCTIONS")}</h2>
      {#if $activationCode === "104"}
        <p>
          Welcome to the Sensay English assessment. There are 3 questions total
          that will take approximately 5 minutes to complete. The goal of this
          assessment is to gauge your English speaking fluency and skill level.
          You will have time to prepare your answers to each question in
          advance.
        </p>
        <p>Let's get started!</p>
      {/if}
      <!-- <p class="lead mb-4">
        {$_("INTRO_TEXT")}
      </p> -->
      <p class="lead mb-4">
        <strong>{$_("TECHNICAL_REQUIREMENTS")}</strong>
        {$_("TECHNICAL_REQUIREMENTS_DETAIL")}
      </p>

      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        {#if loadingState === "NEW"}
          <button
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3"
            on:click={confirmStart}>{$_("START")}</button
          >
        {:else}
          <div class="spinner-border align-center" role="status">
            <span class="sr-only">{$_("LOADING")}</span>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
