<script>
  import { _ } from "svelte-i18n";
  import { init, getLocaleFromNavigator } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFileNoResponse } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    termsAndConditionOpen,
    privacyPolicyOpen,
    activationCode,
  } from "../utilities/DataStore.js";
  import TermsAndConditions from "./components/TermsAndConditions.svelte";
  import PrivacyPolicy from "./components/PrivacyPolicy.svelte";

  export let activationCodeDefault;

  let errorMsg = undefined;
  let loadingState = "NEW";

  let radioAssessmentType = "ENGDEMO";
  let email;
  let name;
  let activationCodeValue;

  let isValidEmail;
  let isValidName;
  let isValidActivationCode;
  let submitTry = false;

  let language;

  onMount(async () => {
    language = getLocaleFromNavigator();
    if (!["en-US", "zh-TW"].includes(language)) {
      language = "en-US";
    }

    const url_string = window.location.href;
    const url = new URL(url_string);
    activationCodeValue = url.searchParams.get("activationCode");
    url.searchParams.delete("activationCode");
    console.log("activationCode", activationCodeValue);

    console.log(222, activationCodeValue, activationCodeDefault);
  });

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function validateEmail(value) {
    var input = document.createElement("input");

    input.type = "email";
    input.required = true;
    input.value = value;

    return input.checkValidity();
  }

  function startAssessment() {
    submitTry = true;

    if (email) {
      isValidEmail = validateEmail(email);
    } else {
      isValidEmail = false;
    }

    if (name) {
      isValidName = true;
    } else {
      isValidName = false;
    }

    if (
      activationCodeValue === "104" ||
      activationCodeValue === "PARO" ||
      activationCodeValue === "STUDY2021" ||
      activationCodeValue === "CLN2021" ||
      activationCodeValue === "TRIAL2021" ||
      activationCodeValue === "NTNU" ||
      activationCodeValue === "DEMO" ||
      activationCodeValue === "LITEON"||
      activationCodeValue === "LITEON2"||
      activationCodeValue === "ELPAC"||
      activationCodeValue === "PILOT2503"
    ) {
      isValidActivationCode = true;
    } else {
      isValidActivationCode = false;
    }

    // isValidActivationCode = true;

    if (isValidEmail && isValidName && isValidActivationCode) {
      $assessmentId = makeid(7);
      // $assessmentType = radioAssessmentType;
      $userId = email;
      $userName = name;
      $activationCode = activationCodeValue;

      loadingState = "LOADING";

      uploadUserInit().then(() => {
        $assessmentState = "START";
        $currentPage = "AssessmentSelection";
      });
    }
  }

  function onChangeType(event) {
    radioAssessmentType = event.currentTarget.value;
  }

  async function uploadUserInit() {
    const uploadUserInit = {
      name: name,
      email: email,
      assessmentId: $assessmentId,
      assessmentType: "INIT",
      activationCode: $activationCode,
      uiLanguage: language,
    };

    await postJsonFileNoResponse(
      BASE_URL + "/public/assessment/meetTaipei",
      { "Content-Type": "application/json" },
      {},
      uploadUserInit,
    )
      .then((data) => {
        console.log("send data to sensay", data);
        mixpanel.track("MeetTaipeiTracking", uploadUserInit);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }

  function changeLanguage(value) {
    language = value;
    console.log("changeLanguage", language);
    if (language && (language === "en-US" || language === "zh-TW")) {
      init({
        fallbackLocale: "en",
        initialLocale: language,
      });
    } else {
      init({
        fallbackLocale: "en",
        initialLocale: getLocaleFromNavigator(),
      });
    }
  }
</script>

<TermsAndConditions />
<PrivacyPolicy />
<div class="container">
  <div class="my-5 text-center">
    <div class="container col-xl-12 col-xxl-10 px-4 py-1">
      <div class="row align-items-start g-lg-5 py-1">
        <div
          class="col-lg-7 text-left align-items-start text-lg-start mb-3 mt-4"
        >
          <!-- <img
            class="d-block mx-auto mb-4"
            src="assets/img/logo_2_report.png"
            alt=""
            width="200px"
          /> -->

          <div class="mb-4" style="margin-top: 2em;">
            <span
              ><a
                href="https://www.oksensay.com/" target="_blank" rel="noopener noreferrer"
                ><img
                  style="margin-right: 1em;"
                  src="assets/img/logo_horizontal_nobg2.png"
                /></a
              ></span
            >
           
          </div>
          <h3 class="display-6 fw-bold lh-1 mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT")}
          </h3>
          <!-- <h3 class="display-6 fw-bold lh-1 mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT_EXPLANATION")}
          </h3> -->
          <!-- <h5 class="display-8 fw-bold lh-1 mb-3">{$_("WE_OFFER_TWO_TESTS")}</h5> -->
          <!-- <div class="text-center mb-3">
            {$_("ENG1_AND_ENG2_ARE_FREE_EXPLANATION")}
          </div> -->
          <p class="text-left mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT_EXPLANATION_GENERIC_1")}
          </p>

          <p class="text-left mb-3">
            {$_("ENGLISH_SPEAKING_ASSESSMENT_EXPLANATION_GENERIC_2")}
          </p>
          <!-- <div class="text-center mb-3">
            {$_("ITS_GREAT_PRACTICE")}
          </div> -->
          <!-- <div class="text-center">
            {$_("FREE_REPORT_WITH_APROXIMATES")}
          </div> -->

          
        </div>
        <div class="col-sm-12 col-md-10 mx-auto col-lg-5">
          <form class="p-4 p-md-5 border rounded-3 bg-light">
            <h4 class="mb-4">Discover Your English Proficiency Level Today</h4>
            <div class="form-floating mb-3">
              <label for="emailInput">{$_("EMAIL_ADDRESS")}</label><input
                type="email"
                bind:value={email}
                class="form-control"
                id="emailInput"
                placeholder="name@example.com"
                class:is-valid={submitTry && isValidEmail}
                class:is-invalid={submitTry && !isValidEmail}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">
                {$_("EMAIL_ADDRESS_VALIDATION")}
              </div>
            </div>

            <div class="form-floating mb-3">
              <label for="nameInput">{$_("NAME")}</label><input
                type="text"
                bind:value={name}
                class="form-control"
                id="nameInput"
                placeholder="name"
                class:is-valid={submitTry && isValidName}
                class:is-invalid={submitTry && !isValidName}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">{$_("NAME_VALIDATION")}</div>
            </div>

            <div class="form-floating mb-3">
              <label for="codeInput">{$_("ACTIVATION_CODE")}</label><input
                type="text"
                bind:value={activationCodeValue}
                class="form-control"
                id="codeInput"
                placeholder=""
                class:is-valid={submitTry && isValidActivationCode}
                class:is-invalid={submitTry && !isValidActivationCode}
              />
              <div class="valid-feedback" />
              <div class="invalid-feedback">{$_("CODE_VALIDATION")}</div>
            </div>

            <div class="form-floating mb-3">
              <label for="codeInput">{$_("INTERFACE_LANGUAGE")}</label>
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                bind:value={language}
                on:change={(event) => {
                  changeLanguage(event.target.value);
                }}
              >
                <option value="en-US">{$_("en-US")}</option>
                <option value="zh-TW">{$_("zh-TW")}</option>
              </select>
            </div>

            {#if loadingState === "NEW"}
              <button
                class="w-100 btn btn-lg btn-primary mt-4"
                type="button"
                on:click={startAssessment}>{$_("CHOOSE_ASSESSMENT")}</button
              >
            {:else}
              <div class="spinner-border align-center" role="status">
                <span class="sr-only">{$_("LOADING")}</span>
              </div>
            {/if}

            <hr class="my-4" />
            {$_("TERMS_PART1")}<button
              class="btn btn-link p-0"
              on:click|preventDefault={() => ($termsAndConditionOpen = true)}
              >{$_("TERMS_PART2")}</button
            >
            {$_("TERMS_PART3")}
            <button
              class="btn btn-link p-0"
              on:click|preventDefault={() => ($privacyPolicyOpen = true)}
              >{$_("TERMS_PART4")}</button
            >
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .yellow-banner {
    color: #1e1e1e;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #ffcc07;
    padding: 0.3em;
    font-family: "Noto Sans", serif;
  }

  h3 {
    font-family: "Noto Sans", serif;
    font-weight: 800;
    font-size: xxx-large;
  }

  h4 {
    text-align: left;
    font-family: "Noto Sans", serif;
    font-weight: 500;
  }

  label {
    text-align: left;
    text-transform: uppercase;
  }

  p {
    font-family: "Noto Sans", serif;
  }
</style>
