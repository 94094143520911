<script>
  import { _ } from "svelte-i18n";
  import { onMount } from "svelte";
  import { currentPage, activationCode, invitationNumber } from "../../utilities/DataStore";
  import { router } from "tinro";

  export let instanceId;
  export let instanceData;

  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  function initialize() {
    console.log(instanceData);
  }

  onMount(() => {
    initialize();
  });

  function shareButton() {
    if (navigator.share) {
      navigator
        .share({
          title: "Share Sensay Assessment Report",
          url: `https://assessment.oksensay.com/#/certificate/${instanceId}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      copyLink();
    }
  }

  let linkCopied = false;

  let timer;

  const debounce = (v) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      linkCopied = false;
    }, 5000);
  };

  function copyLink() {
    let url = `https://assessment.oksensay.com/#/certificate/${instanceId}`;
    copyTextToClipboard(url);
  }

  function copyTextToClipboard(text) {
    linkCopied = true;
    debounce();
    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      },
    );
  }

  function backToSelection() {
    router.goto("/");
    if ($activationCode) {
      $currentPage = "AssessmentSelection";
    } else {
      $currentPage = "";
    }
  }
</script>

<div class="container paper-shadow">
  <div class="row justify-content-center">
    <div class="col-auto">
      <div class="mb-4">
        <span
          ><a href="https://www.oksensay.com/blog/assessment"
            ><img
              style="margin-right: 1em;"
              src="assets/img/logo_horizontal_nobg2.png"
            /></a
          ></span
        >
      </div>
    </div>
  </div>

  <div class="row mt-4 justify-content-center">
    <div class="col-auto">
      <p class="text">
        Thank you for taking this assessment. The final result will be recalculated by examiner assessors and the system.
      </p>
    </div>
  </div>

  {#if $activationCode || $invitationNumber}
    <div class="row mt-4 justify-content-center">
      <div class="col-auto">
        <button class="btn btn-success" on:click={backToSelection}
          >{$_("BACK_TO_SELECTION")}</button
        >
      </div>
    </div>
  {/if}
</div>

<style>
  .text {
    font-size: large;
  }

  .paper-shadow {
    position: relative;
    background: #fff;
    padding: 3rem;
    /* width: 50%; */
    margin: 50px auto;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.2);
    /* height: 400px; */
  }
  .paper-shadow:after,
  .paper-shadow:before {
    content: "";
    position: absolute;
    left: auto;
    background: none;
    z-index: -1;
  }
  .paper-shadow:after {
    width: 90%;
    height: 10px;
    top: 30px;
    right: 8px;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
    -webkit-box-shadow: 0px -20px 36px 5px #295d92;
    -moz-box-shadow: 0px -20px 36px 5px #295d92;
    box-shadow: 0px -25px 35px 0px rgba(0, 0, 0, 0.5);
  }

  .paper-shadow:before {
    width: 10px;
    height: 95%;
    top: 5px;
    right: 18px;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    -webkit-box-shadow: 20px 0px 25px 5px #295d92;
    -moz-box-shadow: 20px 0px 25px 5px #295d92;
    box-shadow: 22px 0px 35px 0px rgba(0, 0, 0, 0.5);
  }

  hr.hr-text {
    position: relative;
    border: none;
    height: 1px;
    background: #999;
  }

  hr.hr-text::before {
    content: attr(data-content);
    display: inline-block;
    background: #fff;
    font-weight: bold;
    font-size: 0.85rem;
    color: #999;
    border-radius: 30rem;
    padding: 0.2rem 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
