<script>
  import { _ } from "svelte-i18n";
  import { Route, router, meta } from "tinro";
  import { BASE_URL } from "../utilities/config.js";
  import { createEventDispatcher, onMount } from "svelte";
  import { postJsonFile } from "../utilities/Utils.svelte";
  import RangeSlider from "svelte-range-slider-pips";
  import { postJsonFileNoResponse } from "../utilities/Utils.svelte";
  import {
    currentPage,
    skillId,
    cid,
    userId,
    userName,
    assessmentType,
    assessmentId,
    instanceId,
    assessmentState,
    assessmentDefinitionId,
    activationCode,
  } from "../utilities/DataStore";

  let errorMsg = undefined;
  let loadingState = "NEW";
  let certificationScore = [0];
  let certificationType = undefined;
  let certificationOtherName = "";
  let selfAssessmentScore = [0];
  let showError = false;
  let isSliderTouched = false;

  const cefrLevel = [
    $_("NONE"),
    "A1",
    "A2",
    "B1",
    "B2",
    "C1",
    "C2",
    $_("NATIVE"),
  ];

  const cefrLevelDetail = [
    $_("NONE"),
    $_("A1_LEVEL"),
    $_("A2_LEVEL"),
    $_("B1_LEVEL"),
    $_("B2_LEVEL"),
    $_("C1_LEVEL"),
    $_("C2_LEVEL"),
    $_("NATIVE"),
  ];

  onMount(async () => {});

  function confirmNext() {
    console.log(
      666,
      certificationScore,
      certificationType,
      selfAssessmentScore
    );

    if (!isSliderTouched && selfAssessmentScore[0] == 0) {
      showError = true;
      return;
    }
    let certificationTypeValue;
    let certificationScoreValue;
    if (certificationType === "OTHER") {
      certificationTypeValue = certificationOtherName;
      certificationScoreValue = certificationScore;
    } else {
      certificationTypeValue = certificationType;
      certificationScoreValue = certificationScore[0];
    }
    loadingState = "LOADING";
    uploadUserInit(
      certificationTypeValue,
      certificationScoreValue,
      cefrLevel[selfAssessmentScore[0]]
    )
      .then((data) => {
        //router.goto("/assessment/" + $instanceId);
        $currentPage = "IntroductionPage";
      })
      .catch((error) => {
        errorMsg = "Error" + error;
      });
  }

  function skipNext() {
    console.log(
      555,
      certificationScore,
      certificationType,
      selfAssessmentScore
    );
    loadingState = "LOADING";
    uploadUserInit(undefined, undefined, cefrLevel[selfAssessmentScore[0]])
      .then((data) => {
        //router.goto("/assessment/" + $instanceId);
        $currentPage = "IntroductionPage";
      })
      .catch((error) => {
        errorMsg = "Error" + error;
      });
  }

  async function uploadUserInit(
    certificationTypeValue,
    certificationScoreValue,
    selfAssessmentScoreValue
  ) {
    const uploadUserInit = {
      name: $userName,
      email: $userId,
      assessmentId: $assessmentId,
      assessmentType: $assessmentType,
      certificationType: certificationTypeValue,
      certificationScore: certificationScoreValue,
      selfEvaluationLevel: selfAssessmentScoreValue,
      activationCode: $activationCode,
    };

    await postJsonFileNoResponse(
      BASE_URL + "/public/assessment/meetTaipei",
      { "Content-Type": "application/json" },
      {},
      uploadUserInit
    )
      .then((data) => {
        console.log("send data to sensay", data);
        mixpanel.track("MeetTaipeiTracking", uploadUserInit);
      })
      .catch((error) => {
        console.error("error sending data to sensay", error);
      });
  }
</script>

<div class="container">
  <div class="px-4 py-5 my-1 text-center">
    <h1 class="display-5 fw-bold">{$_("PREVIOUS_ASSESSMENT")}</h1>

    <div class="col-lg-9 mx-auto">
      <h2 class="lead mb-4">{$_("PREVIOUS_ASSESSMENT_PROMPT")}</h2>
      <div class="form-floating mb-4">
        <label for="codeInput">{$_("CERTIFICATION_TYPE")}</label>
        <select
          class="form-control"
          id="exampleFormControlSelect1"
          bind:value={certificationType}
        >
          <option value="">{$_("NONE")}</option>
          <option value="TOEFL_IBT">{$_("TOEFL_IBT")}</option>
          <option value="TOEFL_ITP">{$_("TOEFL_ITP")}</option>
          <option value="TOEIC">{$_("TOEIC")}</option>
          <option value="IELTS">{$_("IELTS")}</option>
          <option value="OTHER">{$_("OTHER")}</option>
        </select>
      </div>

      {#if certificationType === "TOEFL_IBT"}
        <div class="form-floating language-level">
          <label for="customRange3" class="form-label">{$_("SCORE")}</label>
          <RangeSlider
            pips
            float
            all="label"
            min={0}
            max={120}
            step={1}
            pipstep={25}
          />
        </div>
      {:else if certificationType === "TOEFL_ITP"}
        <div class="form-floating language-level">
          <label for="customRange3" class="form-label">{$_("SCORE")}</label>
          <RangeSlider
            bind:values={certificationScore}
            pips
            float
            all="label"
            last={false}
            first={false}
            min={300}
            max={667}
            step={1}
            pipstep={100}
          />
        </div>
      {:else if certificationType === "TOEIC"}
        <div class="language-level form-floating">
          <label for="customRange3" class="form-label">{$_("SCORE")}</label>
          <RangeSlider
            bind:values={certificationScore}
            pips
            float
            all="label"
            min={0}
            max={990}
            step={1}
            pipstep={100}
          />
        </div>
      {:else if certificationType === "IELTS"}
        <div class="language-level form-floating">
          <label for="customRange3" class="form-label">{$_("SCORE")}</label>
          <RangeSlider
            bind:values={certificationScore}
            pips
            float
            all="label"
            min={0}
            max={9}
            step={0.5}
            pipstep={2}
          />
        </div>
      {:else if certificationType === "OTHER"}
        <div class="mb-4 form-floating">
          <label for="examScore" class="form-label"
            >{$_("CERTIFICATION_NAME")}</label
          >
          <input
            bind:value={certificationOtherName}
            class="form-control"
            id="examScore"
          />
          <label for="examScore" class="form-label">{$_("SCORE")}</label>
          <input
            bind:value={certificationScore}
            class="form-control"
            id="examScore"
          />
        </div>
      {/if}
    </div>

    <div class="col-lg-9 mx-auto">
      <h2 class="lead mb-4">{$_("SELF_ASSESSMENT_PROMPT")}</h2>
      <div class="form-floating mb-4">
        <div class="selfassessment form-floating">
          <label for="customRange3" class="form-label">{$_("SCORE")}</label>
          <RangeSlider
            bind:values={selfAssessmentScore}
            on:start={() => {
              isSliderTouched = true;
            }}
            on
            float
            pips
            all="label"
            formatter={(v) => cefrLevel[v]}
            max={cefrLevel.length - 1}
          />
        </div>
        <p>{cefrLevelDetail[selfAssessmentScore[0]]}</p>
      </div>

      {#if showError && !isSliderTouched}
        <div class="alert alert-danger" role="alert">
          {$_("ALERT_SELECT_VALUE")}
        </div>
      {/if}
      <div class="move-next d-grid gap-2 d-sm-flex justify-content-sm-center">
        {#if loadingState === "NEW"}
          <button
            type="button"
            class="btn btn-primary btn-lg px-4 gap-3"
            on:click={confirmNext}>{$_("NEXT")}</button
          >
          <!-- <button type="button" class="btn btn-text ml-1" on:click={skipNext}>
            {$_("SKIP")}
          </button> -->
        {:else}
          <div class="spinner-border align-center" role="status">
            <span class="sr-only">{$_("LOADING")}</span>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style>
  .move-next {
    margin-top: 4em;
  }

  .language-level {
    margin-bottom: 6em !important;
  }

  .selfassessment-level {
    margin-bottom: 4em !important;
  }
</style>
